import { Observable, Subject, merge, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { CrudList, ECacheTag, RestApiResponse, Setting, Settings, SettingsUpdateRequest } from '@ls-front/sharable';

import { ApiService } from './api.service';

@Injectable()
export class SettingsService {
    public settings$: Observable<Settings>;
    private forceGetSettings$ = new Subject<void>();

    constructor(private api: ApiService) {
        this.settings$ = merge(of(undefined), this.forceGetSettings$).pipe(
            switchMap(() => this.getSettings()),
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    public getSettings(): Observable<Settings> {
        return this.api.getCached<CrudList<Setting>>('/rest_api_v3/settings/get-list', ECacheTag.settings)
            .pipe(map(data => Settings.from(new CrudList(data, Setting).items)));
    }

    public forceGetSettings(): void {
        this.forceGetSettings$.next();
    }

    public updateSettings(request: SettingsUpdateRequest): Observable<RestApiResponse> {
        return this.api.updateCached<RestApiResponse>('/rest_api_v3/settings/update', [ECacheTag.settings], request)
            .pipe(tap(() => this.forceGetSettings()));
    }
}
