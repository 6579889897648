export class ProfileRaw {
    public id?: number;
    public name?: string;
    public profile?: string;
    public sort?: number;
    public categoryId?: number;

    constructor(data?: Partial<ProfileRaw>) {
        this.id = data && data.id;
        this.name = data && data.name;
        this.profile = data && data.profile;
        this.sort = data && data.sort;
        this.categoryId = data && data.categoryId;
    }
}
