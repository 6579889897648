import { ArrayNotEmpty, IsArray, IsInt } from 'class-validator';

export class OrderCloseAfterStickerPrintRequest {
    @IsArray()
    @ArrayNotEmpty()
    @IsInt({ each: true })
    public positionIdList: number[];

    constructor(data?: Partial<OrderCloseAfterStickerPrintRequest>) {
        this.positionIdList = data?.positionIdList;
    }
}

