/**
 * Main order status used in DB
 */
export enum OrderStatus {
    STATUS_NEW = 1,
    STATUS_PENDING = 3,
    STATUS_IN_WORK = 2,
    STATUS_CLOSED = 10,
    STATUS_SHIPPED = 11,
}

/**
 * Order status flow:
 * | FROM      | TO       | TRIGGER                                   |
 * |-----------|----------|-------------------------------------------|
 * | -         | NEW      | Every new order is set to NEW by default  |
 * | PENDING   | NEW      | Clicked button "Return to Drafts"         |
 * | NEW       | PENDING  | Order paid OR clicked button "To Work"    |
 * | PENDING   | IN_WORK  | Order stickers printed                    |
 * | IN_WORK   | CLOSED   | Clicked button "READY"                    |
 * | CLOSED    | SHIPPED  | Clicked button "SHIP"                     |
 */
const eligibleOrderStatusTransition: { from: OrderStatus; to: OrderStatus }[] = [
    // TODO: task-20220 - new status transitions
    // { from: undefined, to: OrderStatus.STATUS_NEW },
    // { from: OrderStatus.STATUS_PENDING, to: OrderStatus.STATUS_NEW },
    // { from: OrderStatus.STATUS_NEW, to: OrderStatus.STATUS_PENDING },
    // { from: OrderStatus.STATUS_PENDING, to: OrderStatus.STATUS_IN_WORK },
    // { from: OrderStatus.STATUS_IN_WORK, to: OrderStatus.STATUS_CLOSED },
    // { from: OrderStatus.STATUS_CLOSED, to: OrderStatus.STATUS_SHIPPED },

    // TODO: task-20220 - old status transitions
    { from: undefined, to: OrderStatus.STATUS_NEW },
    { from: OrderStatus.STATUS_PENDING, to: OrderStatus.STATUS_NEW },
    { from: OrderStatus.STATUS_IN_WORK, to: OrderStatus.STATUS_NEW },
    { from: OrderStatus.STATUS_NEW, to: OrderStatus.STATUS_PENDING },
    { from: OrderStatus.STATUS_NEW, to: OrderStatus.STATUS_IN_WORK },
    { from: OrderStatus.STATUS_PENDING, to: OrderStatus.STATUS_IN_WORK },
    { from: OrderStatus.STATUS_IN_WORK, to: OrderStatus.STATUS_CLOSED },
    { from: OrderStatus.STATUS_CLOSED, to: OrderStatus.STATUS_SHIPPED },
];

export function orderCanChangeStatus(from: OrderStatus, to: OrderStatus): boolean {
    return eligibleOrderStatusTransition.some(transition => transition.from === from && transition.to === to);
}

export function orderGetEligibleFromStatuses(statusTo: OrderStatus): OrderStatus[] {
    return eligibleOrderStatusTransition
        .filter(transition => transition.to === statusTo)
        .map(transition => transition.from)
        .filter(status => !!status);
}

const orderStatusLabel: Record<OrderStatus, string> = {
    [OrderStatus.STATUS_NEW]: 'Новый',
    [OrderStatus.STATUS_PENDING]: 'В очереди',
    [OrderStatus.STATUS_IN_WORK]: 'В работе',
    [OrderStatus.STATUS_CLOSED]: 'На складе',
    [OrderStatus.STATUS_SHIPPED]: 'Отгружен',
};

export function orderStatusToLabel(status: OrderStatus): string {
    return orderStatusLabel[status];
}

export function orderStatusesToLabels(statusList: OrderStatus[]): string[] {
    return statusList.map(status => orderStatusToLabel(status));
}

/**
 * Order status in text form for more convenient usage
 */
export type OrderStatusTxt = 'new' | 'pending' | 'work' | 'closed' | 'shipped';

/**
 * @deprecated Use OrderStatusTxt
 */
export type TOrderStatus = OrderStatusTxt | 'all';

const orderStatusMap: Record<OrderStatusTxt, OrderStatus> = {
    new: OrderStatus.STATUS_NEW,
    pending: OrderStatus.STATUS_PENDING,
    work: OrderStatus.STATUS_IN_WORK,
    closed: OrderStatus.STATUS_CLOSED,
    shipped: OrderStatus.STATUS_SHIPPED,
};

export function orderStatusFrom(value: OrderStatusTxt): OrderStatus {
    return orderStatusMap[value];
}

export function orderStatusListFrom(list: OrderStatusTxt[]): OrderStatus[] {
    return Array.isArray(list)
        ? list.flatMap(value => (orderStatusMap[value] ? orderStatusMap[value] : []))
        : [];
}
