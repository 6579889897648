import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { PermissionName } from '@ls-front/sharable';

import { SessionService } from '../_service/session/session.service';
import { UserService } from '../_service/user.service';

@Injectable()
export class MainGuard {
    constructor(private router: Router, private session: SessionService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (route.firstChild.data.loginByToken) {
            return true;
        }

        return this.session.userSession$.pipe(
            take(1),
            map(userSession => {
                if (!userSession.isLoggedIn()) {
                    const target = state.url.split('?')[0];
                    const params = JSON.stringify(route.queryParams);
                    this.router.navigate(['/login'], { queryParams: { t: target, p: params } })
                        .catch(err => console.log(err));
                    return false;
                }
                if (UserService.can(PermissionName.F_EDIT_IN_WORK_ORDER) && state.url === '/order/list/new') {
                    this.router.navigate(['order/list/in-work']).catch(err => console.log(err));
                    return false;
                }
                /** по дефолту редирект на /order/list/new, но не все имеют туда доступ, таких редиректим на главную */
                if (!userSession.can([PermissionName.F_EDIT_ORDER, PermissionName.D_EDIT_OWN_ORDER]) && (state.url === '/order/list/new')) {
                    this.router.navigate(['/']).catch(err => console.log(err));
                    return false;
                }
                return true;
            })
        );
    }
}
