import { IsIn, IsNumber, IsOptional } from 'class-validator';

import { ProfileSourceType } from './ProfileSourceType';

export class ProfileGetListRequest {
    @IsIn([ProfileSourceType.personal, ProfileSourceType.franchiser])
    @IsOptional()
    public source?: ProfileSourceType;

    @IsNumber()
    @IsOptional()
    public contractorId?: number;

    @IsNumber()
    @IsOptional()
    public categoryId?: number;

    constructor(data?: Partial<ProfileGetListRequest>) {
        this.source = data && data.source;
        this.contractorId = data && data.contractorId;
        this.categoryId = data && data.categoryId;
    }
}
