import { IsIn, IsOptional, IsNumber } from 'class-validator';

import { ProfileSourceType } from '../profile';
export class ProfileCategoryGetListRequest {
    @IsIn([ProfileSourceType.personal, ProfileSourceType.franchiser])
    @IsOptional()
    public source?: ProfileSourceType;

    @IsNumber()
    @IsOptional()
    public contractorId?: number;

    constructor(data?: Partial<ProfileCategoryGetListRequest>) {
        this.source = data?.source;
        this.contractorId = data?.contractorId;
    }
}
