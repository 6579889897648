import { IsInt, IsNotEmpty, IsString } from 'class-validator';

export class AreaRemoveNomenclatureTypeRequest {
    @IsNotEmpty()
    @IsString()
    public nomTypeUuid: string;

    @IsInt()
    public areaId: number;

    constructor(data?: Partial<AreaRemoveNomenclatureTypeRequest>) {
        this.nomTypeUuid = data?.nomTypeUuid;
        this.areaId = data?.areaId;
    }
}
