import { IsBoolean, IsInt, IsOptional, IsString, ValidateNested } from 'class-validator';

import { jsonParse } from '../utils/jsonParse';

import { SettingsInfo } from './SettingsInfo';
import { Setting } from './Setting';
import { AssemblyLocation } from './assembly-location/assembly-location';

export class Settings implements SettingsInfo {
    @IsOptional()
    @IsInt()
    public readonly bendSizeMM: number;

    @IsString()
    public readonly videoUrl: string;

    @IsOptional()
    @IsString()
    public readonly bankName: string;

    @IsOptional()
    @IsString()
    public readonly bankINN: string;

    @IsOptional()
    @IsString()
    public readonly bankBIK: string;

    @IsOptional()
    @IsString()
    public readonly bankKPP: string;

    @IsOptional()
    @IsString()
    public readonly bankCorrespondentAccount: string;

    @IsOptional()
    @IsString()
    public readonly bankPaymentAccount: string;

    @IsOptional()
    @IsString()
    public readonly organisationName: string;

    @IsOptional()
    @IsString()
    public readonly organisationINN: string;

    @IsOptional()
    @IsString()
    public readonly organisationKPP: string;

    @IsOptional()
    @IsString()
    public readonly organisationPhones: string;

    @IsOptional()
    @IsString()
    public readonly organisationPostAddress: string;

    @IsOptional()
    @IsString()
    public readonly organisationActualAddress: string;

    @IsOptional()
    @IsString()
    public readonly organisationOGRN: string;

    @IsOptional()
    @IsString()
    public readonly organisationCertificate: string;

    @IsOptional()
    @IsString({ each: true })
    public readonly serviceComments?: string[];

    @IsOptional()
    @IsString({ each: true })
    public readonly pickupAddressList?: string[];

    @IsOptional()
    @ValidateNested()
    public readonly assemblyLocationList?: AssemblyLocation[];

    @IsOptional()
    @IsBoolean()
    public readonly stockBalanceControl: boolean;

    @IsOptional()
    @IsBoolean()
    public readonly orderPerformersControl: boolean;

    @IsOptional()
    @IsBoolean()
    public readonly printBarcodeStickers: boolean;

    constructor(data?: Partial<Settings>) {
        this.bendSizeMM = data?.bendSizeMM || 15;
        this.videoUrl = data?.videoUrl || '';

        this.bankName = data?.bankName;
        this.bankINN = data?.bankINN;
        this.bankBIK = data?.bankBIK;
        this.bankKPP = data?.bankKPP;
        this.bankCorrespondentAccount = data?.bankCorrespondentAccount;
        this.bankPaymentAccount = data?.bankPaymentAccount;

        this.organisationName = data?.organisationName;
        this.organisationINN = data?.organisationINN;
        this.organisationKPP = data?.organisationKPP;
        this.organisationPhones = data?.organisationPhones;
        this.organisationPostAddress = data?.organisationPostAddress;
        this.organisationActualAddress = data?.organisationActualAddress;
        this.organisationOGRN = data?.organisationOGRN;
        this.organisationCertificate = data?.organisationCertificate;
        this.serviceComments = data?.serviceComments;
        this.pickupAddressList = data?.pickupAddressList;
        this.assemblyLocationList = data?.assemblyLocationList;
        this.stockBalanceControl = data?.stockBalanceControl ?? false;
        this.orderPerformersControl = data?.orderPerformersControl ?? false;
        this.printBarcodeStickers = data?.printBarcodeStickers ?? false;
    }

    public static from(settings: Setting[]): Settings {
        const data: { [name in keyof SettingsInfo]?: string } = settings.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {});

        const toNumber: (value?: string) => number | undefined = value => value && parseFloat(value) || undefined;
        return new Settings({
            bendSizeMM: toNumber(data?.bendSizeMM),
            videoUrl: data?.videoUrl,
            bankName: data?.bankName,
            bankINN: data?.bankINN,
            bankBIK: data?.bankBIK,
            bankKPP: data?.bankKPP,
            bankCorrespondentAccount: data?.bankCorrespondentAccount,
            bankPaymentAccount: data?.bankPaymentAccount,
            organisationName: data?.organisationName,
            organisationINN: data?.organisationINN,
            organisationKPP: data?.organisationKPP,
            organisationPhones: data?.organisationPhones,
            organisationPostAddress: data?.organisationPostAddress,
            organisationActualAddress: data?.organisationActualAddress,
            organisationOGRN: data?.organisationOGRN,
            organisationCertificate: data?.organisationCertificate,
            serviceComments: jsonParse(data?.serviceComments),
            pickupAddressList: jsonParse(data?.pickupAddressList),
            assemblyLocationList: AssemblyLocation.listFrom(data?.assemblyLocationList),
            stockBalanceControl: data?.stockBalanceControl === 'true',
            orderPerformersControl: data?.orderPerformersControl === 'true',
            printBarcodeStickers: data?.printBarcodeStickers === 'true',
        });
    }

    public toList(filterBy?: (keyof SettingsInfo)[]): Setting[] {
        const settingList: Setting[] = [
            new Setting({ name: 'bendSizeMM', value: this.bendSizeMM && `${this.bendSizeMM}` }),
            new Setting({ name: 'videoUrl', value: this.videoUrl }),
            new Setting({ name: 'bankName', value: this.bankName }),
            new Setting({ name: 'bankINN', value: this.bankINN }),
            new Setting({ name: 'bankBIK', value: this.bankBIK }),
            new Setting({ name: 'bankKPP', value: this.bankKPP }),
            new Setting({ name: 'bankCorrespondentAccount', value: this.bankCorrespondentAccount }),
            new Setting({ name: 'bankPaymentAccount', value: this.bankPaymentAccount }),
            new Setting({ name: 'organisationName', value: this.organisationName }),
            new Setting({ name: 'organisationINN', value: this.organisationINN }),
            new Setting({ name: 'organisationKPP', value: this.organisationKPP }),
            new Setting({ name: 'organisationPhones', value: this.organisationPhones }),
            new Setting({ name: 'organisationPostAddress', value: this.organisationPostAddress }),
            new Setting({ name: 'organisationActualAddress', value: this.organisationActualAddress }),
            new Setting({ name: 'organisationOGRN', value: this.organisationOGRN }),
            new Setting({ name: 'organisationCertificate', value: this.organisationCertificate }),
            new Setting({ name: 'serviceComments', value: JSON.stringify(this.serviceComments) }),
            new Setting({ name: 'pickupAddressList', value: JSON.stringify(this.pickupAddressList) }),
            new Setting({ name: 'assemblyLocationList', value: AssemblyLocation.listTo(this.assemblyLocationList) }),
            new Setting({ name: 'stockBalanceControl', value: this.stockBalanceControl ? 'true' : 'false' }),
            new Setting({ name: 'orderPerformersControl', value: this.orderPerformersControl ? 'true' : 'false' }),
            new Setting({ name: 'printBarcodeStickers', value: this.printBarcodeStickers ? 'true' : 'false' }),
        ];

        return Array.isArray(filterBy) ? settingList.filter(setting => filterBy.indexOf(setting.name) > -1) : settingList;
    }
}
