import { ErrorHandler, Injectable } from '@angular/core';

import { environment } from '@ls-front/env';

import { LoggerService } from './logger/logger.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    constructor(private logger: LoggerService) {
    }

    public handleError(error: unknown): void {
        if (!environment.production) {
            console.log(error);
        }
        this.logger.error('Unhandled error', error);
    }
}
