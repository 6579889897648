import { Observable, interval } from 'rxjs';
import { filter, map, mergeMap, shareReplay, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Lisa10Parameters, OrganisationInfo } from '@ls-front/sharable';

import { ApiService } from './api.service';
import { SessionService } from './session/session.service';

@Injectable({ providedIn: 'root' })
export class GeneralService {
    public hideUserMenu = false;

    private params: Lisa10Parameters = {
        version: '',
        supportEmail: '',
        supportPhone: '',
        findUnacceptedAnnouncementsForLastDays: -1,
    };

    private cacheOrganisationInfo$: Observable<OrganisationInfo>;
    private pingInterval = 300000;

    constructor(private api: ApiService, route: ActivatedRoute, sessionService: SessionService) {
        route.queryParams.subscribe(params => {
            if (params.hasOwnProperty('nousermenu') || params.hasOwnProperty('noUserMenu')) {
                this.hideUserMenu = true;
            }
        });

        // Get params from backend
        this.getParams().subscribe(params => Object.assign(this.params, params));

        // Пингуем сервер по таймеру
        interval(this.pingInterval).pipe(
            mergeMap(() => sessionService.userSession$.pipe(take(1))),
            filter(userSession => userSession.isLoggedIn()),
            mergeMap(() => this.api.post('/rest_api_v2/main/refresh-token'))
        ).subscribe();
    }

    public param<ParamKey extends keyof Lisa10Parameters>(paramName: ParamKey): Lisa10Parameters[ParamKey] {
        return this.params[paramName];
    }

    public getOrganisationInfo(): Observable<OrganisationInfo> {
        if (!this.cacheOrganisationInfo$) {
            this.cacheOrganisationInfo$ = this.api.post<{ organisationInfo: OrganisationInfo }>('/rest_api_v3/general/get-organisation-info')
                .pipe(map(data => new OrganisationInfo(data?.organisationInfo)), shareReplay(1));
        }
        return this.cacheOrganisationInfo$;
    }

    private getParams(): Observable<Lisa10Parameters> {
        return this.api.post<{ lisa10Parameters: Lisa10Parameters }>('/rest_api_v3/general/get-lisa10-parameters').pipe(
            filter(data => typeof data?.lisa10Parameters === 'object'),
            map(data => data.lisa10Parameters)
        );
    }
}
