import { IsInt, IsNotEmpty, IsOptional, IsString, MaxLength } from 'class-validator';

export class ProfileCategoryUpdateRequest {
    @IsOptional()
    @IsInt()
    public id?: number;

    @IsString()
    @IsNotEmpty()
    @MaxLength(255)
    public name: string;

    @IsInt()
    public sort: number;

    @IsOptional()
    @IsInt()
    public userId: number;

    constructor(data?: Partial<ProfileCategoryUpdateRequest>) {
        this.id = data && data.id;
        this.name = data && data.name;
        this.sort = data && data.sort;
        this.userId = data && data.userId;
    }
}
