import { IsNumber, IsOptional, IsString } from 'class-validator';

import { TaxonomyValues } from '../../taxonomy';
import { NomenclatureUnitComposition } from '../NomenclatureUnitComposition';

export class NomenclaturePositionSumRequest {
    @IsNumber()
    public orderId: number;

    @IsString()
    public nomPositionUuid: string;

    @IsNumber()
    public qty: number;

    @IsOptional()
    public values?: TaxonomyValues;

    @IsOptional()
    public composition?: NomenclatureUnitComposition[];

    @IsOptional()
    @IsNumber()
    public contrId?: number;

    constructor(data?: Partial<NomenclaturePositionSumRequest>) {
        this.orderId = data?.orderId;
        this.nomPositionUuid = data?.nomPositionUuid;
        this.qty = data?.qty;
        this.values = data?.values;
        this.composition = data?.composition;
        this.contrId = data?.contrId;
    }
}
