/**
 * Created by optimistex on 16.06.17.
 */

import { StickerType } from '@ls-front/sharable';

export const configStickers = {
    /** Значения типов этикеток на позицию. Определяет формирование этикеток для позиции заказа. */
    STICK_TYPE: {
        NO_PRINT: StickerType.NO_PRINT,
        PER_UNIT: StickerType.PER_UNIT,
        PER_SET: StickerType.PER_SET,
    },

    /** Типы этикеток в печать. Определяет внешний вид этикетки при печати. */
    PRINT_TYPE: {
        MAIN: 'STICKER_TYPE_MAIN' as const,
        PARAMS: 'STICKER_TYPE_PARAMS' as const,
        PROFILE: 'STICKER_TYPE_PROFILE' as const,
        UNWRAP_PROFILE: 'STICKER_TYPE_UNWRAP_PROFILE' as const,
        SUMMARY: 'STICKER_TYPE_SUMMARY' as const,
        PAID_ONLINE: 'STICKER_TYPE_PAID_ONLINE' as const,
        SERVICE_COMMENT: 'STICKER_TYPE_SERVICE_COMMENT' as const,
        PICKUP_ADDRESS: 'STICKER_TYPE_PICKUP_ADDRESS' as const,
        BAR_CODE: 'STICKER_TYPE_BAR_CODE' as const,
    },

    /** Максимальная длина имен/значений параметров. При превышении параметры выносятся на отдельную этикетку */
    LIMITS: {
        MAX_LEN_NAMES: 30,
        MAX_LEN_VALUES: 15,
        MAX_LEN_ONE_VALUE: 18,
    },
};
