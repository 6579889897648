import { UserLoginInfo } from '@ls-front/sharable';

export class UserSession extends UserLoginInfo {
    constructor(data?: Partial<UserLoginInfo>) {
        super({
            ...data,
            group: data?.group || '',
            groupTitle: data?.groupTitle || '',
            firstName: data?.firstName || '',
            middleName: data?.middleName || '',
            lastName: data?.lastName || '',
            roles: data?.roles || [],
            permissions: data?.permissions || [],
            areas: data?.areas || [],
            username: data?.username || '',
        });
    }

    public static fromString(data: string): UserSession {
        try {
            return new UserSession(JSON.parse(data));
        } catch {
            return new UserSession();
        }
    }

    public toString(): string {
        return JSON.stringify(this);
    }

    public isLoggedIn(): boolean {
        return !!this.token;
    }

    public getAuthToken(): string {
        return this.token ? 'Bearer ' + this.token : undefined;
    }

    /**
     * Checking access permissions
     */
    public can(permissionName: string | string[]): boolean {
        for (const permName of [].concat(permissionName)) {
            if (this.permissions.indexOf(permName) > -1) {
                return true;
            }
        }
        return false;
    }

    public get isSuperAdmin(): boolean {
        return this.username === 'admin';
    }
}
