import { replace } from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { Injectable } from '@angular/core';

import { IToastOptions } from '../interface/i-toast-options';

const messagesMap = {
    /* eslint-disable @typescript-eslint/naming-convention */
    // Info
    1500: '',

    // Success
    2500: '',

    // Warning
    3500: '',
    3501: 'Вы уверены что хотите удалить {object}?',
    3502: 'При удалении контактного лица будет удален связанный с ним пользователь. Продолжить?',
    3503: 'Контактное лицо не было удалено. Нельзя удалить контакт не удалив пользователя',

    // Error
    4500: '',
    4501: 'При заполнении формы были допущены ошибки. Пожалуйста исправьте их и повторите попытку сохранения',
    4502: 'Неверный формат электронного адреса. Адрес должен содержать знаки ‘@’ и ‘.’',
    4503: 'В номере телефона можно вводить только цифры',
    4504: 'В дате должны вводиться только числа',
    4505: 'В числовом поле допустимы только цифры и знак ‘-’ в начале',
    4506: 'В поле числового формата с точкой разрешен только ввод цифр и знаков ‘-’ и ‘.’',
    4507: 'Слишком простой пароль. Правила создания пароля: одна заглавная буква, один символ, '
        + 'одна цифра, минимум 8 символов',
    4508: 'Нельзя копировать пароль',
    4509: 'Поле "{param}" обязательно для ввода',
    4510: 'Поле может содержать только буквы, цифры и символы подчеркивания',
    4511: 'Нельзя удалить самого себя',
    4512: 'Email-адреса не совпадают',
    4513: 'Нельзя скопировать Email-адрес',
    4514: 'Нельзя снять роль “Администратор” у самого себя',
    4515: 'Вы уже авторизованы в системе. Для прохода по ссылке восстановления пароля, '
        + 'выйдите из своей текущей сессии и повторите попытку',
    4516: 'Пароли не совпадают',
    4528: 'Заказ недоступен для редактирования',
    /* eslint-enable */
};

interface IMessage {
    code: number;
    title?: string;
    text: string;
}

@Injectable()
export class MessengerService {
    constructor(private toastrService: ToastrService) {
        // Auto close toasts by click
        // this._toast.onClickToast().subscribe((toast: Toast) => {
        //     if (!toast.config.showCloseButton) {
        //         clearTimeout(toast.timeoutId);
        //         this._toast.dismissToast(toast);
        //     }
        // });
    }

    public customMessage(code: number, title: string, text: string, options: IToastOptions = {}): void {
        this.showMessage({ code, title, text }, {}, options);
    }

    public message(code: keyof typeof messagesMap, params: Record<string, string> = {}, options: IToastOptions = {}): void {
        const msg = this.getMessage(code, params);
        this.showMessage(msg, params, options);
    }

    public info(text: string, params: Record<string, string> = {}, options: IToastOptions = {}): void {
        this.showMessage({ code: 1500, text }, params, options);
    }

    public success(text: string, params: Record<string, string> = {}, options: IToastOptions = {}): void {
        this.showMessage({ code: 2500, text }, params, options);
    }

    public warning(text: string, params: Record<string, string> = {}, options: IToastOptions = {}): void {
        this.showMessage({ code: 3500, text }, params, options);
    }

    public error(text: string, params: Record<string, string> = {}, options: IToastOptions = {}): void {
        this.showMessage({ code: 4500, text }, params, options);
    }

    private showMessage(message: IMessage, params: Record<string, string> = {}, options: IToastOptions = {}): void {
        const code = message.code;
        const title = message.title ? ' ' + message.title : '';
        // eslint-disable-next-line no-control-regex
        const text = this.setParamsToText(message.text, params).replace(new RegExp('\r?\n', 'g'), '<br />');

        if (code >= 4000 || (400 <= code && code < 600)) {
            this.toastrService.error(text, code.toString() + title, Object.assign({ toastLife: 300000 }, options));
            console.log('error', code, text);
        } else if (code >= 3000 || (300 <= code && code < 400)) {
            this.toastrService.warning(text, code.toString() + title, options);
            console.log('warning', code, text);
        } else if (code >= 2000 || (200 <= code && code < 300)) {
            this.toastrService.success(text, code.toString() + title, options);
            console.log('success', code, text);
        } else {
            this.toastrService.info(text, code.toString() + title, options);
            console.log('info', code, text);
        }
    }

    private getMessage(code: keyof typeof messagesMap, params: Record<string, string> = {}): IMessage {
        const text = messagesMap.hasOwnProperty(code) ? messagesMap[code] : '';
        return { code, text: this.setParamsToText(text, params) };
    }

    private setParamsToText(text: string, params: Record<string, string>): string {
        params = params || {};
        return replace(text, /{([\s\S]+?)}/g, (fullParamName, paramName) => (params.hasOwnProperty(paramName) ? params[paramName] : ''));
    }
}
