import { ArrayNotEmpty, IsArray, IsDefined, IsInt, IsOptional, ValidateIf, isDefined } from 'class-validator';

export class OrderStickerListReportRequest {
    @IsOptional()
    @IsInt()
    public areaId?: number;

    @ValidateIf((o: OrderStickerListReportRequest) => isDefined(o.orderId) || !isDefined(o.positionIdList))
    @IsDefined({ message: '"orderId" is required when "positionIdList" is undefined' })
    @IsInt()
    public orderId?: number;

    @ValidateIf((o: OrderStickerListReportRequest) => isDefined(o.positionIdList) || !isDefined(o.orderId))
    @IsDefined({ message: '"positionIdList" is required when "orderId" is undefined' })
    @IsArray()
    @ArrayNotEmpty()
    @IsInt({ each: true })
    public positionIdList?: number[];

    @ValidateIf((o: OrderStickerListReportRequest) => isDefined(o.orderId) && isDefined(o.positionIdList))
    @IsDefined({ message: '"orderId" and "positionIdList" should not be defined together' })
    private forbiddenTwoProperties: undefined;

    constructor(data?: Partial<OrderStickerListReportRequest>) {
        this.areaId = data?.areaId;
        this.orderId = data?.orderId;
        this.positionIdList = data?.positionIdList;
    }
}
